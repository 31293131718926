<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <artist-view-artist-info-card :artist-data="form" />
        </b-col>
      </b-row>
      <b-row v-if="form['bio']">
        <b-col cols="12">
          <b-card no-body>
            <b-card-body>
              <b-card-title>bio</b-card-title>
              <b-card-sub-title>
                {{ form.bio }}
              </b-card-sub-title>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col cols="12">
          <artist-view-artist-permissions-card  />
        </b-col>
      </b-row> -->
    </template>
  </div>
</template>

<script>
import router from '@/router'
import { ref, onMounted } from '@vue/composition-api'
import {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
} from 'bootstrap-vue'
import ArtistRepository from '@/abstraction/repository/artistRepository'
import ArtistViewArtistInfoCard from './ArtistViewArtistInfoCard.vue'
// import ArtistViewArtistPermissionsCard from "./ArtistViewArtistPermissionsCard.vue";
// repository
const artistRepository = new ArtistRepository()

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        // Local Components
        ArtistViewArtistInfoCard,
    // ArtistViewArtistPermissionsCard,
    },
    setup() {
        const form = ref(null)
        const artistId = ref(router.currentRoute.params.id)
        onMounted(async () => {
            if (artistId.value) {
                const resource = await artistRepository.show(artistId.value)
                form.value = resource
            }
        })
        return {
            form,
        }
    },
}
</script>

<style></style>
