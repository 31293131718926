<template>
  <b-card v-if="artistData">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="artistData ? artistData.thumbnail : 'https://via.placeholder.com'"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ artistData.first_name }} {{ artistData.last_name }}
              </h4>

              <span class="card-text">{{ artistData.cell_number }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-artists-edit', params: { id: artistData.id } }"
                variant="danger"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteArtist(artistData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">First Name</span>
            </th>
            <td class="pb-50">
              {{ artistData.first_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Arabic First Name</span>
            </th>
            <td class="pb-50">
              {{ artistData.first_name_ar }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Last Name</span>
            </th>
            <td class="pb-50">
              {{ artistData.last_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Arabic Last Name</span>
            </th>
            <td class="pb-50">
              {{ artistData.last_name_ar }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">email</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ artistData.email }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        xl="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">cell_number</span>
            </th>
            <td class="pb-50">
              {{ artistData.cell_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              admin
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              Iran
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50">
              Qom
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
    BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'

// repository
import ArtistRepository from '@/abstraction/repository/artistRepository'

const artistRepository = new ArtistRepository()

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        artistData: {
            // type: Object,
            required: true,
        },
    },
    methods: {
        async deleteArtist(artistId) {
            await artistRepository.delete(artistId)
            router.push({ name: 'apps-artists-list' })
        },
    },
}
</script>

<style></style>
